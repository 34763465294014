<template>
  <div class="body-box">
    <div class="d-flex jc-between h-100">
      <!-- 左边数据 -->
      <div class="d-flex flex-column left-content">
        <div class="d-flex flex-column rank-wrapper">
          <ContentTop
            title="赛事活动排名"
            :type="2"
            :topOptionsProps="rankTopOptionsProps"
            @topSelectChange="topMatchSelectChangeRank"
          />
          <div class="rank-radio d-flex jc-end w-100">
            <Radio
                :initData="initRankData"
              :radioProps="activeRankRadioProps"
              @radioChange="topMatchRadioChangeRank"
            />
          </div>
          <div class="rank-list">
            <rank-list :listWidth="400" view-type="other" :listHeight="430" :tableTitle="activeRankTableTitle" :list="activeRankList" />
          </div>
        </div>
        <div class="d-flex flex-column data-wrapper">
          <ContentTop
            title="运动赛事数据"
            :type="2"
            :topOptionsProps="rankTopOptionsProps"
            @topSelectChange="topMatchSelectChange"
          />
          <div class="slider-process-wrapper">
            <i class="lade-icon"></i>
            <div class="slider-process-content">
              <div class="slider-process-left" :style="'width:'+ macthData.man+'%'">
                <span class="num">{{macthData.man}}%</span>
              </div>
              <div class="slider-process-right" :style="'width:'+ macthData.woman+'%'">
                <span class="num">{{macthData.woman}}%</span>
              </div>
            </div>
            <i class="fale-icon"></i>
          </div>
          <p class="distribute-center-text">年龄分布</p>
          <div class="data-distribute d-flex h-100 w-100">
            <div class="distribute-left-content w-100">
              <div class="distribute-img"></div>
              <div v-for="(item,index) in macthData.data" :key="index"
                   :class="`distribute-process-item line-${index} distribute-center-text`">
                {{item.float}}%
              </div>
            </div>
            <div class="distribute-right-content d-flex flex-column">
              <div v-for="(item,index) in macthData.data" :key="index" :class="`distribute-data-item line-${index}`">
                {{item.title}}岁 {{item.num}}人
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 中间数据 -->
      <div class="middle w-100 h-100">
        <div class="middler-wrapper d-flex flex-column">
          <div class="middle-top d-flex">
            <div class="match-total-num match-num-item d-flex">
              <div class="total-num-icon match-icon"></div>
              <div class="text d-flex ai-center">赛事活动总数:</div>
              <div class="total-num number-content d-flex ai-center">
                <span class="num">{{ totalNumber }}</span>
                <span class="unit">场</span>
              </div>
            </div>
            <div class="match-server-num match-num-item d-flex">
              <div class="server-num-icon match-icon"></div>
              <div class="text d-flex ai-center">服务总人数:</div>
              <div class="server-num number-content d-flex ai-center">
                <span class="num">{{ totalPeopleNumber }}</span>
                <span class="unit">人</span>
              </div>
            </div>
          </div>
          <div class="middle-bottom">
            <div class="middle-bottom-wrapper d-flex flex-column">
              <div class="sign-in-wrapper d-flex flex-column">
                <div class="sign-in-content d-flex">
                  <div class="img1">
                    <piechart
                      :pieData="cyData"
                      style="width: 550px"
                    ></piechart>
                  </div>
                  <div class="img-label d-flex flex-column jc-center">
                    <div class="label-item d-flex ai-center d-flex">
                      <span class="color-block" style="background-color: #01f9cc;"></span>
                      <span class="label-text">市级协会</span>
                      <span class="label-unit" style="color: #01f9cc;">{{pieLengde.top1}}%</span>
                    </div>
                    <div class="label-item d-flex ai-center d-flex">
                      <span class="color-block" style="background-color: #02adf2;"></span>
                      <span class="label-text">县市级协会</span>
                      <span class="label-unit" style="color: #02adf2;">{{pieLengde.top2}}%</span>
                    </div>
                    <div class="label-item d-flex ai-center d-flex">
                      <span class="color-block" style="background-color: #fcbc01;"></span>
                      <span class="label-text">各县市区</span>
                      <span class="label-unit" style="color: #fcbc01;">{{pieLengde.top3}}%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <ContentTop
                  title="各区县协会赛事活动排名"
                  :topOptionsProps="activeRankTopSelectProps"
                  @topSelectChange="topAreaRankSelectChange"
                />
                <div class="active-rank-list">
                  <rank-list
                    :listWidth="700"
                    :listHeight="350"
                    :tableTitle="activeRankTitle"
                    :list="areaRankList"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 右边数据 -->
      <div class="rigth-content d-flex flex-column h-100">
        <div class="d-flex flex-column">
          <ContentTop title="赛事数量排名" :type="2" />
          <div class="rank-radio d-flex jc-end w-100">
            <Radio
              :radioProps="{}"
            />
          </div>
          <Sliders :height="280+'px'" :options="matchLevelRankList" @sliderChange="sliderChange" />
        </div>
        <div class="city-active-wrapper d-flex flex-column">
          <ContentTop title="市级协会赛事活动排名" :topOptionsProps="cityActiveOptionProps"
                      @topSelectChange="topCityRankSelectChange" :type="2" />
          <div class="rank-list">
            <rank-list :listHeight="500"  :tableTitle="cityRankTableTitle" :list="cityRankList" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContentTop from '@/components/contentTop';
import Radio from '@/components/Radio';
import rankList from "@/components/rankList";
import piechart from '@/components/pieChart';
import Sliders from '@/components/Sliders'
import service from "@/utils/request";
import {numberToMoney} from "@/utils";

export default {
  components: { ContentTop, Radio, rankList, piechart, Sliders },
  data() {
    return {
      totalNumber: 0,
      totalPeopleNumber: 0,
      pieLengde: {
        top1: 0, // 市级协会
        top2: 0, // 县市级协会
        top3: 0 // 各县市
      },
      matchLevelRankList: [],
      macthData: {
        data: [],
        man: 0,
        woman: 0
      },
      // 赛事活动排名活动数据
      rankTopOptionsProps: {
        selects: [
          {
            name: 'one',
            options: this.$store.state.areaList
          }
        ]
      },
      initRankData: '',
      // 赛事活动排名单选数据
      activeRankRadioProps: {
        name: 'rankRadio',
        width: 130,
        options: [
          { value: '1', label: '周' },
          { value: '3', label: '月' },
          { value: '2', label: '季' },
          { value: '0', label: '全' }
        ]
      },
      // 赛事级别排名单选数据
      levelRankRadioProps: {
        name: 'levelRankRadio',
        width: 131,
        options: [
          { value: 'qu', label: '区级' },
          { value: 'xian', label: '县级' },
          { value: 'shi', label: '市级' }
        ]
      },
      activeRankTableTitle: [
        { title: '排名', width: '80' },
        { title: '赛事活动排名', val:'first', width: '120' },
        { title: '活动场数', val:'second', width: '100' },
        { title: '服务人次', val:'three', width: '100' },
      ],
      activeRankList: [
        { first:'羽毛球赛事', second:'20场', three:'900次' },
        { first:'游泳赛事', second:'19场', three:'800次' },
        { first:'乒乓球赛事', second:'18场', three:'700次' },
        { first:'跑步赛事', second:'17场', three:'600次' },
        { first:'排球赛事', second:'16场', three:'500次' },
        { first:'篮球赛事', second:'15场', three:'400次' },
        { first:'足球赛事', second:'14场', three:'300次' },
        { first:'武术赛事', second:'13场', three:'200次' },
        { first:'网球赛事', second:'12场', three:'100次' }
      ],
      cyData: [],
      activeRankTopRadioProps: {
        name: 'activeRank',
        width: 60,
        options: [
          { value: 'qu', label: '区' },
          { value: 'xian', label: '县' }
        ]
      },
      activeRankTopSelectProps: {
        selects: [
          {
            name: 'select1',
            options: this.$store.state.areaList
          }
        ]
      },
      activeRankTitle: [{
        title: '排名',
        width: '100'
      }, {
        title: '赛事活动名称',
        val:'first',
        width: '380'
      }, {
        title: '活动人数',
        val:'second',
        width: '280'
      }],
      areaRankList: [],
      // 市级协会赛事活动排名 top下拉
      cityActiveOptionProps: {
        selects: [
          {
            name: 'cityTopSelect',
            options: this.$store.state.areaList
          }
        ]
      },
      cityRankTableTitle: [
        { title: '排名', width: 80 },
        { title: '赛事活动名称', val:'first', width: 170 },
        { title: '活动人数', val:'second', width: 130 },
      ],
      cityRankList: [],
      matchRankParam: {
        code: '',
        type: 0
      },
      timing: null,
      matchDataCode: '',
      CityRankDataCode: '',
      AreaRankDataCode: '',
    }
  },
  mounted() {
    this.getCenterData();
    this.getMatchRankData();
    this.getMatchData('');
    this.getCityRankData('');
    this.getAreaRankData('');
    this.getMatchLevelRankList('');
    this.timeFn();
  },
  beforeDestroy() {
    clearInterval(this.timing);
  },
  methods: {
    timeFn() {
      this.timing = setInterval(() => {
        this.getCenterData();
        this.getMatchRankData();
        this.getMatchData(this.matchDataCode);
        this.getCityRankData(this.CityRankDataCode);
        this.getAreaRankData(this.AreaRankDataCode);
        this.getMatchLevelRankList('');
      }, 5000);
    },
    getCenterData() {
      const myThis = this;
      service.get('/act/statistics')
          .then((response) => {
            if(response.code != 200) {
              console.log(response.message);
              return false;
            }
            const data = response.data;
            const denominator = data.municipal_level_act_num + data.county_level_act_num + data.other_level_act_num;
            myThis.totalNumber = numberToMoney(data.act_num);
            myThis.totalPeopleNumber = numberToMoney(data.service_num);
            myThis.pieLengde = {
              top1: ((data.municipal_level_act_num / denominator)*100).toFixed(2), // 市级协会
              top2: ((data.county_level_act_num / denominator)*100).toFixed(2), // 县市级协会
              top3: ((data.other_level_act_num / denominator)*100).toFixed(2) // 各县市
            }
            myThis.cyData = [{
                name: '市级协会',
                value: denominator == 0 ? 0 : (data.municipal_level_act_num / denominator)*100,
                itemStyle: {
                  color: '#01f9cc',
                }
              },
              {
                name: '',
                value: 0,
                itemStyle: {
                  color: 'rgba(0,0,0,0)',
                }
              }, {
                name: '县市级协会',
                value: denominator == 0 ? 0 : (data.county_level_act_num / denominator)*100,
                itemStyle: {
                  color: '#02adf2'
                }
              },
              {
                name: '',
                value: 0,
                itemStyle: {
                  color: 'rgba(0,0,0,0)',
                }
              },
              {
                name: '各县市区',
                value: denominator == 0 ? 0 : (data.other_level_act_num / denominator)*100,
                itemStyle: {
                  color: '#fcbc01'
                }
              }
            ];
          })
          .catch((response) => {
            console.log(response)
          })
    },
    getMatchRankData() {
      // 赛事活动排名
      const myThis = this;
      service.get('/act/listRanking',{params: myThis.matchRankParam})
          .then((response) => {
            if(response.code != 200) {
              console.log(response.message);
              return false;
            }
            const data = response.data;
            myThis.activeRankList = data.map((item) => {
              return { first:item.title, second:item.num+'场', three:item.service_num+'次' }
            })
          })
          .catch((response) => {
            console.log(response)
          })
    },
    getMatchLevelRankList() {
      // 赛事级别排名
      const myThis = this;
      service.get('/act/actLevelRanking',{params: myThis.matchRankParam})
          .then((response) => {
            if(response.code != 200) {
              console.log(response.message);
              return false;
            }
            const data = response.data;
            myThis.matchLevelRankList = data.map((item) => {
              return { value: item.num, label: item.name, rightText: item.num+'' }
            })
          })
          .catch((response) => {
            console.log(response)
          })
    },
    getMatchData(code) {
      // 运动赛事数据
      const myThis = this;
      service.get('/act/userEnroll',{params: {code: code}})
          .then((response) => {
            if(response.code != 200) {
              console.log(response.message);
              return false;
            }
            const data = response.data;
            let allData = 0;
            if(data.woman != 0 && data.man != 0) {
              myThis.macthData.man = (data.man * 100 / (data.man + data.woman)).toFixed(1);
              myThis.macthData.woman = (data.woman * 100 / (data.man + data.woman)).toFixed(1);
            } else {
              myThis.macthData.man = 0;
              myThis.macthData.woman = 0;
            }
            data.list.map((item) => {
              allData = allData + item.num;
            });
            const list = data.list.sort((a,b) => b.num - a.num);
            myThis.macthData.data = list.map((item) => {
              let float = 0;
              if(allData != 0) {
                float = (item.num * 100 / allData).toFixed(1);
              }
              return { title:item.title, num: numberToMoney(item.num),float: float}
            })
          })
          .catch((response) => {
            console.log(response)
          })
    },
    getCityRankData(code) {
      // 市级协会赛事活动排名
      const myThis = this;
      service.get('/act/cityBusRanking',{params: {code: code}})
          .then((response) => {
            if(response.code != 200) {
              console.log(response.message);
              return false;
            }
            const data = response.data;
            myThis.cityRankList = data.map((item) => {
              return { first:item.org_com, second:item.num+'人' }
            })
          })
          .catch((response) => {
            console.log(response)
          })
    },
    getAreaRankData(code) {
      // 市级协会赛事活动排名
      const myThis = this;
      service.get('/act/areaBusRanking',{params: {code: code}})
          .then((response) => {
            if(response.code != 200) {
              console.log(response.message);
              return false;
            }
            const data = response.data;
            myThis.areaRankList = data.map((item) => {
              return { first:item.org_com, second:item.num+'人' }
            })
          })
          .catch((response) => {
            console.log(response)
          })
    },
    fomatFloat(num, n) {
      var f = parseFloat(num);
      if (isNaN(f)) {
        return false;
      }
      f = Math.round(num * Math.pow(10, n)) / Math.pow(10, n); // n 幂
      var s = f.toString();
      var rs = s.indexOf('.');
      //判定如果是整数，增加小数点再补0
      if (rs < 0) {
        rs = s.length;
        s += '.';
      }
      while (s.length <= rs + n) {
        s += '0';
      }
      return s;
    },
    topMatchSelectChangeRank(name, value){
      this.matchRankParam.code = value;
      this.getMatchRankData();
    },
    topMatchSelectChange(name, value) {
      this.matchDataCode = value;
      this.getMatchData(value);
    },
    topMatchRadioChangeRank(name, value) {
      this.matchRankParam.type = value;
      this.getMatchRankData();
    },
    topCityRankSelectChange(name, value) {
      this.CityRankDataCode = value;
      this.getCityRankData(value);
    },
    topAreaRankSelectChange(name, value) {
      this.AreaRankDataCode = value;
      this.getAreaRankData(value);
    },
    sliderChange(index, value) {
      console.log(index, value)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/matchActivity.scss";
</style>
